

let stat = {

    Moment : require('moment'),
    QueryYear : null,

    Init : () => {
        stat.GetQueryYear();
        stat.QuotationChart();
        stat.TurnOverChar();
        stat.ProjectChart();
    },

    GetQueryYear : () => {
        let uri = window.location.href;
        if (uri.indexOf('?query_year=')) {
            stat.QueryYear = uri.split('?query_year=')[1];
        } else {
            stat.QueryYear = stat.Moment.format('YYYY');
        }
    },

    MonthLabel : () => {
        return [
            'jan',
            'fev',
            'mar',
            'avr',
            'mai',
            'juin',
            'juil',
            'aou',
            'sep',
            'oct',
            'nov',
            'dec'
        ]
    },

    ProjectChart : () => {

        if (J('#project_chart').length) {

            const labels        = stat.MonthLabel();
            let defaultDatas    = [2, 10, 60, 30, 20, 10, 80, 30, 50, 90, 60, 20];

            stat.Ajax('/admin/ajax/stat/projects?year='+stat.QueryYear, {}, function(results){

                const data = {
                    labels: labels,
                    datasets: [{
                        label: 'Projets',
                        backgroundColor: 'rgba(54, 162, 235, 0.4)',
                        borderColor: 'rgb(54, 162, 235)',
                        data: (results ? results : defaultDatas),
                    }]
                };
    
                const config = {
                    type: 'line',
                    data,
                    options: {}
                };
                stat.InitChart('project_chart', config);
            });
        }
    },

    TurnOverChar : () => {
        // FACTURATION
        if (J('#turnover_chart').length) {

            const labels = stat.MonthLabel();
            let defaultDatas = [5, 10, 60, 30, 20, 10, 80, 30, 50, 90, 60, 30];

            stat.Ajax('/admin/ajax/stat/numbers?year='+stat.QueryYear, {}, function(results) {

                if (results) {
                    for (var i=0; i < results.length; i++) {
                        if (J('.turn-over-data').eq(i).length) {
                            /*let value = Math.round(results[i]);
                                value = value.toLocaleString();*/
                            let value = results[i].toFixed(2);
                                value = parseFloat(value);
                                value = value.toLocaleString();
                            J('.turn-over-data').eq(i).text( value+'€' );
                        } 
                    }
                }

                const data = {
                    labels: labels,
                    datasets: [{
                        label: 'Facturation',
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(255, 159, 64, 0.2)',
                            'rgba(255, 205, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(201, 203, 207, 0.2)',
                            'rgba(255, 159, 64, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(201, 203, 207, 0.2)',
                        ],
                        borderColor: [
                            'rgb(255, 99, 132)',
                            'rgb(255, 159, 64)',
                            'rgb(255, 205, 86)',
                            'rgb(75, 192, 192)',
                            'rgb(54, 162, 235)',
                            'rgb(153, 102, 255)',
                            'rgb(201, 203, 207)',
                            'rgb(255, 159, 64)',
                            'rgb(54, 162, 235)',
                            'rgb(255, 99, 132)',
                            'rgb(75, 192, 192)',
                            'rgb(201, 203, 207)',
                        ],
                        data: (results ? results : defaultDatas),
                        borderWidth: 1,
                    }]
                };
                const config = {
                    type: 'bar',
                    data,
                    options: {}
                };
                stat.InitChart('turnover_chart', config);
            });
        }
    },

    QuotationChart : () => {
        // DEVIS
        if (J('#quotation_chart').length) {

            const labels        = stat.MonthLabel();
            let defaultDatas    = [42, 10, 60, 30, 20, 10, 80, 30, 50, 90, 60, 65];

            stat.Ajax('/admin/ajax/stat/quotations?year='+stat.QueryYear, {}, function(results){

                const data = {
                    labels: labels,
                    datasets: [{
                        label: 'Devis',
                        backgroundColor: 'rgb(255, 99, 132)',
                        borderColor: 'rgb(255, 99, 132)',
                        data: (results ? results : defaultDatas),
                    }]
                };
                const config = {
                    type: 'line',
                    data,
                    options: {}
                };
                stat.InitChart('quotation_chart', config);
            });
        }
    },

    InitChart : (elementId, config) => {

        let Chart = require('chart.js/dist/chart');

        new Chart(
            document.getElementById(elementId),
            config
        );
    },

    Ajax : (url, data = {}, callback) => {

        let Ajax = J.ajax({
            url : url,
            method: 'POST',
            dataType : 'JSON',
            data: data
        });
        Ajax.done(function(result){
            return callback(result);
        });
        Ajax.fail(function(error){
            return callback(error);
        });
    }
}
module.exports = stat;